import { useCallback, useEffect } from 'react';
import { AppShell, Image } from '@mantine/core';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { Gear, Question } from 'phosphor-react';
import { useAnimate } from 'framer-motion';

import { useGlobalContext } from '@/contexts/GlobalContext';

import { useNavItems } from '@/components/navBar/useNavItems';
import NavLink from './NavLink';

import { ArenaTextLogo } from '../../utils/ArenaTextLogo';
import { CommonProps } from '../types';
import { useBreakpoint } from '../hooks/useBreakpoint';

const NavWrapper = ({ children, className }: CommonProps) => (
    <div className={cx('NavWrapper flex flex-col gap-4 py-5', className)}>{children}</div>
);

const NavGroup = ({ children, className }: CommonProps) => (
    <div className={cx('NavGroup', className)}>{children}</div>
);

export default function ResponsiveNavBar() {
    const router = useRouter();
    const { navbarIsHovering, updateIsNavbarHovering, closeMobileNav } = useGlobalContext(
        state => state,
    );

    const [scope, animate] = useAnimate();
    const mq = useBreakpoint();

    const pages = useNavItems();

    useEffect(
        function animateArenaTextLogo() {
            if (!scope.current || !router.isReady) return;
            if (mq.down.md) {
                animate(scope.current, { width: 'auto' });
            } else {
                animate(scope.current, { width: navbarIsHovering ? 125 : 0 });
            }
        },
        [navbarIsHovering, animate, scope, router.isReady, mq.down.md],
    );

    const onClickNavLink = useCallback(() => {
        updateIsNavbarHovering(false);
        closeMobileNav();
    }, [closeMobileNav, updateIsNavbarHovering]);

    return (
        <AppShell>
            <AppShell.Navbar
                onMouseEnter={() => updateIsNavbarHovering(true)}
                onMouseLeave={() => updateIsNavbarHovering(false)}
                className="glass-effect h-full w-screen shadow-lg max-md:min-w-full"
            >
                <NavGroup className="!pt-6">
                    <NavLink isLogo href="/" isHovering={navbarIsHovering}>
                        <div className="flex w-full items-center justify-center">
                            <Image
                                className="!h-[45px] !w-fit !pl-2"
                                src="/images/arenaLogo.svg"
                                height={45}
                                alt="Logo"
                                onClick={() => {
                                    onClickNavLink();
                                    router.push('/');
                                }}
                            />
                            <div
                                ref={scope}
                                className={cx('overflow-hidden', {
                                    'w-0': mq.md && !navbarIsHovering,
                                })}
                            >
                                <ArenaTextLogo className="fill-primary/70" size={70} />
                            </div>
                        </div>
                    </NavLink>
                </NavGroup>

                <NavGroup className="relative z-40 overflow-auto">
                    <NavWrapper className="!pt-3">
                        {pages.map(item => (
                            <NavLink
                                onClick={onClickNavLink}
                                isHovering={navbarIsHovering}
                                key={item.href}
                                {...item}
                            />
                        ))}
                    </NavWrapper>
                </NavGroup>

                <NavGroup className="mt-auto border-t border-t-secondary">
                    <NavWrapper className="!gap-3">
                        <NavLink
                            href="settings"
                            isHovering={navbarIsHovering}
                            onClick={onClickNavLink}
                        >
                            <Gear size={20} />
                        </NavLink>
                    </NavWrapper>
                </NavGroup>
            </AppShell.Navbar>
        </AppShell>
    );
}
