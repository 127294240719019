import {
    ChartLine,
    Flask,
    Graph,
    Layout,
    ListChecks,
    Megaphone,
    Path,
    Storefront,
    Tag,
    TrendUp,
    UserList,
    Wrench,
} from 'phosphor-react';
import { Microscope } from '@phosphor-icons/react';
import { useMemo } from 'react';
import {
    CONFIGURATIONS_USER_GROUP_WHITELIST,
    HERMES_USER_GROUP_WHITELIST,
    MOST_USED_ABI_USERS_WITH_ARENA_STAFF,
    NavItem,
    ONTOLOGY_USER_GROUP_WHITELIST,
    PERSONALIZATIONS_USER_GROUP_WHITELIST,
    PROMOS_USER_GROUP_WHITELIST,
    PROXIMO_USER_GROUP_WHITELIST,
} from './constants';

export const useNavItems = (): NavItem[] =>
    useMemo(
        () => [
            {
                href: 'console',
                children: <Path size={20} />,
                userGroupWhitelist: MOST_USED_ABI_USERS_WITH_ARENA_STAFF,
            },
            {
                href: 'insights',
                children: <ChartLine size={20} />,
                userGroupWhitelist: MOST_USED_ABI_USERS_WITH_ARENA_STAFF,
                sharableKeys: ['usecase', 'namespace'],
            },
            {
                href: 'experiments',
                children: <Flask size={20} />,
                userGroupWhitelist: MOST_USED_ABI_USERS_WITH_ARENA_STAFF,
                sharableKeys: ['usecase', 'namespace'],
            },
            {
                href: 'personalizations',
                userGroupWhitelist: PERSONALIZATIONS_USER_GROUP_WHITELIST,
                children: <UserList size={20} />,
                sharableKeys: ['usecase', 'namespace'],
            },
            {
                href: 'configurations',
                userGroupWhitelist: CONFIGURATIONS_USER_GROUP_WHITELIST,
                children: <Wrench size={20} />,
                sharableKeys: ['usecase', 'namespace'],
                routerQueryKeys: ['usecase', 'namespace'],
            },
            // {
            //     href: 'search',
            //     children: <MagnifyingGlass size={20} />,
            //     userGroupWhitelist: ['bausch-users'],
            // },
            {
                href: 'hermes',
                children: <ListChecks size={20} />,
                userGroupWhitelist: HERMES_USER_GROUP_WHITELIST,
            },
            {
                href: 'ontology',
                children: <Graph size={20} />,
                userGroupWhitelist: ONTOLOGY_USER_GROUP_WHITELIST,
            },
            {
                href: 'market-performance',
                children: <TrendUp size={20} />,
                userGroupWhitelist: ['proximo-users'],
            },
            {
                href: 'pricing',
                children: <Tag size={20} />,
                userGroupWhitelist: PROXIMO_USER_GROUP_WHITELIST,
            },
            {
                href: 'stores',
                children: <Storefront size={20} />,
                userGroupWhitelist: PROXIMO_USER_GROUP_WHITELIST,
            },
            {
                href: 'market-performance',
                children: <TrendUp size={20} />,
                userGroupWhitelist: PROXIMO_USER_GROUP_WHITELIST,
            },
            {
                href: 'product-analysis',
                children: <Microscope size={20} />,
                userGroupWhitelist: PROXIMO_USER_GROUP_WHITELIST,
            },
            {
                href: 'promos',
                children: <Megaphone size={20} />,
                userGroupWhitelist: PROMOS_USER_GROUP_WHITELIST,
            },
            {
                href: 'catalog',
                children: <Layout size={20} />,
                userGroupWhitelist: MOST_USED_ABI_USERS_WITH_ARENA_STAFF,
            },
        ],
        [],
    );
