import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import { Text } from '@mantine/core';
import { useAnimate } from 'framer-motion';

import { useUserAccess } from '@/hooks/useUserAccess';
import { useAppContext } from '@/contexts/appContext';

import useHrefAccess from '@/components/navBar/useHrefAccess';
import StyledTooltip from '../../utils/styledTooltip';
import { ARENA_DEV_ROUTES, NavItem, PAGE_NAMES, TBD_PAGE_NAMES } from '../../navBar/constants';
import { useBreakpoint } from '../hooks/useBreakpoint';

const getPathFromHref = (href: string) => {
    const index = href.indexOf('?');
    if (index > -1) {
        return href.substring(0, index);
    }
    if (href.includes('/')) return href;
    return `/${href}`;
};

interface Props extends NavItem {
    onClick?: () => void;
}

export default function NavLink({
    href,
    children,
    className = '',
    sharableKeys = [],
    isHovering,
    customLabel,
    tooltipLabel,
    disabled: disabledProp,
    isLogo,
    onClick,
}: Props) {
    const { pathname, ...router } = useRouter();
    const { user } = useAppContext();

    const hasAccessToHref = useHrefAccess({ href });
    const isArenaStaff = useUserAccess({ userGroupWhitelist: ['arena-staff'] });
    const isTBDStaff = useUserAccess({ userGroupWhitelist: ['bausch-users'] });

    const disabled = !isArenaStaff && disabledProp;

    const [scope, animate] = useAnimate();
    const mq = useBreakpoint();

    useEffect(
        function animateText() {
            if (!scope.current || !router.isReady) return;
            if (mq.md) {
                animate(scope.current, { width: isHovering ? 'auto' : 0 });
            } else {
                animate(scope.current, { width: 'auto' });
            }
        },
        [mq.md, isHovering, animate, scope, router.isReady],
    );

    const hrefWithParams = (() => {
        if (sharableKeys.length === 0) return `${href.includes('/') ? href : `/${href}`}`;

        const commonQueries = sharableKeys.reduce((acc: string, key) => {
            const value = router?.query?.[key];
            if (!value) return acc;
            const val = Array.isArray(value) ? value.join(`&${key}=`) : value;
            return `${acc}${acc.includes('?') ? `&${key}=${val}` : `?${key}=${val}`}`;
        }, '');
        return `${href.includes('/') ? href : `/${href}`}${commonQueries}`;
    })();

    if (!hasAccessToHref) return null;

    const label =
        // ? This change is to make sure when we have a value we display it
        customLabel ??
        (isTBDStaff ? (TBD_PAGE_NAMES?.[href] ?? PAGE_NAMES?.[href]) : PAGE_NAMES?.[href]);

    const isActive =
        (getPathFromHref(href) === pathname || pathname.includes(`${getPathFromHref(href)}/`)) &&
        !isLogo;

    return (
        <Link
            id={hrefWithParams}
            href={disabled ? {} : hrefWithParams}
            onClick={e => {
                e.preventDefault();
                if (!disabled) {
                    onClick?.();
                    router.push(hrefWithParams);
                }
            }}
            className={cx(
                'relative flex items-center py-1.5 hover:opacity-100',
                {
                    'opacity-50': !isLogo && !isActive,
                    '!hover:opacity-50 text-navy-70': disabled,
                    'hover:cursor-pointer': !disabled,
                    'cursor-not-allowed': disabled,
                    'pl-6 pr-2.5': !isLogo,
                    'text-pacific border-r-2 border-pacific': isActive,
                    hidden:
                        ARENA_DEV_ROUTES.includes(href) && user.displayDeveloperTools === 'hide',
                },
                className,
            )}
        >
            {isLogo ? (
                children
            ) : (
                <StyledTooltip
                    classNames={{
                        tooltip: '!whitespace-nowrap',
                    }}
                    position="right"
                    withArrow
                    w="auto"
                    arrowSize={12}
                    label={tooltipLabel}
                    disabled={!tooltipLabel}
                >
                    <div className="flex items-center justify-center gap-3">
                        {children}
                        <div
                            ref={scope}
                            className={cx('overflow-hidden whitespace-nowrap', {
                                'w-0': mq.md && !isHovering,
                                'w-auto': mq.down.md,
                            })}
                        >
                            <Text className="pr-3.5 !text-[13px] leading-normal">{label}</Text>
                        </div>
                    </div>
                </StyledTooltip>
            )}
        </Link>
    );
}
